<template>
  <div>
    <div class="login">
      <div>
        <div class="name">后台登录</div>
        <div class="box">
          <div class="portrait">
            <img
              src="https://lycaljdapi.dexian.ren//upload/images/2021/12/6fc2966a-108.png"
            />
          </div>
          <div>
            <div class="loginFrom">
              <input
                type="text"
                placeholder="请输入账号"
                v-model="dataFrom.uname"
              />
              <input
                type="password"
                placeholder="请输入密码"
                v-model="dataFrom.upwd"
              />
              <!-- <button class="iphoneon">发送验证码</button> -->
              <!-- <input type="text" placeholder="请输入手机验证码" /> -->
            </div>
            <!-- <div>账号密码登录</div> -->
            <button @click="submit" class="loginon">登 录</button>
          </div>
        </div>
        <!-- <div class="WXbotton" bindtap="getUserInfo">
          <image
            src="https://lycaljdapi.dexian.ren//upload/images/2021/12/28c4ba52-b36.png"
          ></image>
          <div>微 信 登 录</div>
        </div> -->
      </div>
    </div>
    <van-tabbar
      v-model="active"
      @change="onChangeTab"
      active-color="#ffc002"
      inactive-color="#000"
    >
      <van-tabbar-item icon="home-o">首页</van-tabbar-item>
      <van-tabbar-item icon="chat-o">订单</van-tabbar-item>
      <van-tabbar-item icon="user-circle-o">登录</van-tabbar-item>
    </van-tabbar>
  </div>
</template>
  <script>
import Vue from "vue";
import { Dialog } from "vant";
import { Toast } from "vant";
Vue.use(Toast);
import { SOPlatCheckUser } from "@/api/ylOrder";
export default {
  data() {
    return {
      active: 2,
      dataFrom: {
        uname: "",
        upwd: "",
      },
    };
  },
  methods: {
    // 底部导航栏切换
    onChangeTab: function (row) {
      console.log(row);
      // 首页
      if (row == "0") {
        this.$router.push({
          path: "/ylOrder",
        });
      }
      if (row == "1") {
        this.$router.push({
          path: "/ylOrder/orderList",
        });
      }
    },
    //   登录
    submit() {
      console.log(this.dataFrom);
      if (this.dataFrom.uname == "") {
        Toast.fail("请输入登录账号");
        return false;
      }
      if (this.dataFrom.upwd == "") {
        Toast.fail("请输入登录密码");
        return false;
      }
      SOPlatCheckUser(this.dataFrom).then((res) => {
        if (res.data.code == 0) {
          var list = res.data;
          this.$router.push({
          path: "/CalYl/?accToken="+list.data.token + "&uName=" + list.data.uName + "&oCode=" + list.data.oCode + "&staLevel=" + list.data.staLevel,
        });
        //   app.globalData.accToken = list.data.token;
        //   app.globalData.getuName = list.data.uName;
        //   app.globalData.oCode = list.data.oCode;
        //   app.globalData.staLevel = list.data.staLevel;
        } else {
          Toast.fail(res.data.msg);
        }
      });
    },
  },
};
</script>
  <style scoped>
.login {
  /* background: #ffc200; */
  height: 100vh;
  overflow: hidden;
}
.loginFrom {
  position: relative;
  width: 80%;
  margin: 30px 10%;
}
.loginFrom input {
  background: #eceff7;
  border-radius: 50px;
  height: 35px;
  width: 90%;
  margin: 10px 0;
  font-size: 14px;
  padding: 0 15px;
  color: #333;
  border: 1px solid #eceff7;
}
.loginon {
  background: #ffc300;
  border: 1px solid #ccc;
  border-radius: 30px;
  /* width: 210px !important; */
  width: 80%;
  margin: 0 10%;
  color: #fff;
  font-weight: 400;
  padding: 10px 0;
}
.portrait {
  width: 100px;
  height: 100px;
  margin: 15px auto;
  border-radius: 10px;
  overflow: hidden;
}
.portrait img {
  width: 100%;
  height: 100px;
}
.name {
  color: #000000;
  margin: 30px 20px;
  font-size: 18px;
  text-align: center;
}
.iphoneon {
  position: absolute;
  right: 7px;
  top: 50px;
  width: 65px !important;
  height: 25px;
  line-height: 8px;
  font-size: 10px;
  font-weight: 400;
  padding: 8px 0px !important;
  color: #fff;
  background: #ffc300;
}
.box {
  background: #ffffff;
  width: 80%;
  margin: 0px 10%;
  border-radius: 8px;
  box-shadow: 0px 0px 15px #eae8e8;
  padding: 25px 0 50px;
}
.WXbotton {
  position: absolute;
  bottom: 30px;
  width: 100%;
}
.WXbotton image {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  left: 50%;
  bottom: 25px;
  margin-left: -20px;
}
.WXbotton view {
  text-align: center;
  color: #000;
}
</style>